@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&family=Roboto:wght@700&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-weight: normal;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* webfont */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-size-adjust: none;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

img[src$=".svg"] {
  width: 100%;
}

input[type="submit"], input[type="button"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="text"], input[type="email"], input[type="tel"], textarea {
  -webkit-appearance: none;
}

input[type="text"]:placeholder-shown, input[type="email"]:placeholder-shown, input[type="tel"]:placeholder-shown, textarea:placeholder-shown {
  color: #999;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "Helvetica Neue", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

button {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "Helvetica Neue", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

/* icomoon */
@font-face {
  font-family: 'icomoon';
  src: url("/common/css/fonts/icomoon.eot?b2q2oh");
  src: url("/common/css/fonts/icomoon.eot?b2q2oh#iefix") format("embedded-opentype"), url("/common/css/fonts/icomoon.ttf?b2q2oh") format("truetype"), url("/common/css/fonts/icomoon.woff?b2q2oh") format("woff"), url("/common/css/fonts/icomoon.svg?b2q2oh#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'fa';
  src: url("../webfonts/fa-brands.eot");
  src: url("../webfonts/fa-brands.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-brands.woff2") format("woff2"), url("../webfonts/fa-brands.woff") format("woff"), url("../webfonts/fa-brands.ttf") format("truetype"), url("../webfonts/fa-brands.svg#fontawesome") format("svg");
}

@font-face {
  font-family: 'fa-regular';
  src: url("../webfonts/fa-regular.eot");
  src: url("../webfonts/fa-regular.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-regular.woff2") format("woff2"), url("../webfonts/fa-regular.woff") format("woff"), url("../webfonts/fa-regular.ttf") format("truetype"), url("../webfonts/fa-regular.svg#fontawesome") format("svg");
}

@font-face {
  font-family: 'fa-solid';
  src: url("../webfonts/fa-solid.eot");
  src: url("../webfonts/fa-solid.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-solid.woff2") format("woff2"), url("../webfonts/fa-solid.woff") format("woff"), url("../webfonts/fa-solid.ttf") format("truetype"), url("../webfonts/fa-solid.svg#fontawesome") format("svg");
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* svg */
.svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.svg span {
  display: none !important;
}
